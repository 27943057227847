<template>
  <div>
    <ApiError :errors="errors"></ApiError>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :server-items-length="total"
      :options.sync="options"
      :hide-default-footer="hideFooter"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="customReport">{{ customReport.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom v-if="customReport">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2"
                fab
                small
                @click="onGenerateReport"
                v-bind="attrs"
                v-on="on"
                :disabled="isGeneratingReport"
              >
                <v-icon v-if="!isGeneratingReport">mdi-exit-to-app</v-icon>
                <v-progress-circular indeterminate color="primary" v-if="isGeneratingReport"></v-progress-circular>
              </v-btn>
            </template>
            <span>{{ $i18n.translate("Export Report") }}</span>
          </v-tooltip>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ApiError from "../display/ApiError";
import ApiService from "../../services/api.service";

export default {
  name: "CustomReportTable",
  props: {
    customReportId: Number
  },
  components: {
    ApiError
  },
  data: function() {
    return {
      loading: true,
      errors: {},
      customReport: undefined,
      headers: [],
      items: [],
      isGeneratingReport: false,
      total: 0,
      options: {
        itemsPerPage: 10
      },
      hideFooter: true
    };
  },

  watch: {
    customReportId() {
      this.fetchData();
    },
    options: {
      handler() {
        this.fetchData();
      },
      deep: true
    }
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    onGenerateReport() {
      this.errors = {};
      this.loading = true;
      this.isGeneratingReport = true;
      ApiService.post("/api/customReports/generate/" + this.customReportId)
        .then(() => {
          this.$router.push({ name: "reportDownloads" });
        })
        .catch(error => {
          this.isGeneratingReport = false;
          this.errors = ApiService.getErrorsFromResponse(error);
        })
        .finally(() => {
          this.isGeneratingReport = false;
          this.loading = false;
        });
    },
    fetchData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      if (this.customReportId && this.customReportId > 0) {
        this.errors = {};
        this.loading = true;
        ApiService.get(
          "/api/customReports/view/" +
            this.customReportId +
            "?size=" +
            itemsPerPage +
            "&page=" +
            (page - 1) +
            (sortBy && sortBy.length > 0
              ? "&sort=" + sortBy[0] + "," + ((sortDesc && sortDesc.length > 0) & sortDesc[0] ? "DESC" : "ASC")
              : "")
        )
          .then(({ data }) => {
            this.customReport = data.customReport;
            this.hideFooter = !this.customReport.paging;
            this.items = data.content;
            this.headers = data.customReport.customReportHeaders.map(customReportHeader => {
              return {
                text: customReportHeader.name,
                value: customReportHeader.name,
                sortable: false,
                align:
                  customReportHeader.alignment && customReportHeader.alignment.name
                    ? customReportHeader.alignment.name.toLowerCase()
                    : "left"
              };
            });

            this.total = data.totalElements;

            this.$emit("name", this.customReport.name);
          })
          .catch(error => {
            this.errors = ApiService.getErrorsFromResponse(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }
  }
};
</script>
